
import { IEnvironmentConfig } from '@myclubrewards/shared';

export const environment: IEnvironmentConfig = {
  production: false,
  GRAPHQL_SERVER: 'https://dev.gatewaycasinos.ca/admin/api',
  WEGLOT_API_KEY: 'wg_bb35b298dc460011624ea6011fbf7cc54',
  CLIENT_ID: '7da98bc5ff5e4759a6799d2dc2c5c8b1',
  CLIENT_SECRET : 'e6302b3C5f5347D48C2f221B9e67c73f',
  BASE_URL: 'https://labapi.gatewaycasinos.ca',
  PASSWORD_RESET_TIMEOUT_SEC: '60',
  GOOGLE_SITE_KEY: '6LcNligaAAAAAAQTevcrkpluCDueXkXtW8RxH1Zn',
  CHECK_USER_INACTIVITY_MINS: 5
};
