import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ENVIRONMENT_CONFIG,
  ErrorService,
  IEnvironmentConfig,
  LocalStorageService,
} from '@myclubrewards/shared';
import { Apollo } from 'apollo-angular';
import { assign, get, has, isEqual, isObject, omit } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from './../models/user-profile-modal';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private accountProfileSuj = new BehaviorSubject<UserProfile>(undefined);
  accountProfile$ = this.accountProfileSuj.asObservable();
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    @Inject(ENVIRONMENT_CONFIG) private environment: IEnvironmentConfig,
    private errorService: ErrorService
  ) {}

  createProfileResponseObject(data: UserProfile) {
    const temp = {
      name: get(data, 'profile.fullName', ''),
      id: get(data, 'id', ''),
      playerAddress: {
        addressLine1: get(data, 'address.addressLine1', ''),
        addressLine2: get(data, 'address.addressLine2', ''),
        addressLine3: get(data, 'address.addressLine3', ''),
        primaryAddress: get(data, 'address.primaryAddress', ''),
        city: get(data, 'address.city', ''),
        stateProvCode: get(data, 'address.stateProvCode', ''),
        postCode: get(data, 'address.postCode', ''),
        countryCode: get(data, 'address.countryCode', ''),
      },
      playerEmail: {
        emailAddress: get(data, 'email.emailAddress', ''),
        primaryEmailAddress: get(data, 'email.primaryEmail', ''),
      },
      playerPhone: {
        phoneNumber: get(data, 'contactNumber.phoneNumber', ''),
        primaryContactNo: get(data, 'contactNumber.primaryPhone', ''),
      },
      communicationPreferences: {
        mail: get(data, 'mailToAddress', false),
        email: get(data, 'emailPreference', false),
        notification: get(data, 'notificationPreference', false),
        telephone: get(data, 'phonePreference', false),
      },
    };
    return temp;
  }

  fetchDifference(savedResponse: any, formValue: any) {
    const temp = {};
    for (const key in formValue) {
      if (isObject(formValue[key])) {
        Object.keys(formValue[key]).forEach((nestedKey) => {
          if (
            !isEqual(
              get(formValue, [key, nestedKey]),
              get(savedResponse, [key, nestedKey])
            )
          ) {
            temp[key] = assign({}, temp[key], {
              [nestedKey]: get(formValue, [key, nestedKey]),
              [`old_${nestedKey}`]: get(savedResponse, [key, nestedKey]),
            });
          }
        });
      } else {
        if (formValue[key] !== savedResponse[key]) {
          temp[key] = formValue[key];
          temp[`old_${key}`] = savedResponse[key];
        }
      }
    }

    if (has(temp, 'communicationPreferences')) {
      temp['communicationPreferences'] = omit(
        get(temp, 'communicationPreferences'),
        ['old_mail', 'old_email', 'old_telephone', 'old_notification']
      ) as never;
    }
    return temp;
  }

  getPlayerProfile() {
    const id = this.localStorageService.getUserId();

    return this.http
      .get(
        `${this.environment.BASE_URL}/exp/api/v1/players/${id}/playerProfile`
      )
      .subscribe((res: any) => {
        if (res) {
          if (get(res, 'playerProfile.IsBanned', false)) {
            this.errorService.openDialog(
              {
                status: 403,
              },
              { displayRedirectButton: true },
              {
                disableClose: true,
              }
            );
            return;
          } else {
            const temp = new UserProfile().deserialize({
              userProfileDetails: res,
            });
            this.accountProfileSuj.next(temp);
          }
        }
      });
  }

  //update user profile

  updateUserProfile(id: string, data: any) {
    return this.http.patch(
      `${this.environment.BASE_URL}/exp/api/v1/players/${id}/update-profile`,
      data
    );
  }
}
